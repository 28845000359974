    :root
    {
      --ThemaColor: #305680;/*紺色。主に背景色などに使用*/
      --Color: white;/*以上の色を背景にした際に文字色として使う*/
      --HoverColor: #FFA500;/*マウスカーソルを当てた時に使う色*/
      --DeleteColor: #DB2828;/*赤色。削除ボタンなどに使う。色の指定はfomantic-ui既定の色から*/
      --orange: #FE9A76;/*橙色。色の指定はfomantic-ui既定の色から*/
      --yellow: #FFD700;/*黄色。色の指定はfomantic-ui既定の色から*/
      --olive: #32CD32;/*黄緑色。色の指定はfomantic-ui既定の色から*/
      --green: #016936;/*緑色。色の指定はfomantic-ui既定の色から*/
      --teal: #008080;/*水色。色の指定はfomantic-ui既定の色から*/
      --blue: #0E6EB8;/*青色。ラベルなどに使う。色の指定はfomantic-ui既定の色から*/
      --violet: #EE82EE;/*菫色。色の指定はfomantic-ui既定の色から*/
      --purple: #B413EC;/*紫色。色の指定はfomantic-ui既定の色から*/
      --pink: #FF1493;/*桃色。ラベルなどに使う。色の指定はfomantic-ui既定の色から*/
      --brown: #A52A2A;/*茶色。色の指定はfomantic-ui既定の色から*/
      --grey: #A0A0A0;/*灰色。ラベルなどに使う。色の指定はfomantic-ui既定の色から*/
      --black: #000000;/*黒色。ラベルなどに使う。色の指定はfomantic-ui既定の色から*/
      --aiot_orange: #FF6600;/*橙色。ラベルなどに使う。色の指定はweb216から*/
    }
    /*紺色を使用したボタンなどの色設定に使うクラス*/
    .Thema_Color
    {
      background-color: var(--ThemaColor)!important;
      color: var(--Color)!important;
    }
    /*赤色を使用したボタンなどの色設定に使うクラス*/
    .Delete_Color
    {
      background-color: var(--DeleteColor)!important;
      color: var(--Color)!important;
    }
    .orange_button_color
    {
        background-color: var(--orange)!important;
        color: var(--Color)!important;
    }
    .yellow_button_color
    {
        background-color: var(--yellow)!important;
        color:var(--Color)!important;
    }
    .olive_button_color
    {
        background-color: var(--olive)!important;
        color:var(--Color)!important;
    }
    .green_button_color
    {
        background-color: var(--green)!important;
        color:var(--Color)!important;
    }
    .teal_button_color
    {
        background-color: var(--teal)!important;
        color:var(--Color)!important;
    }
    .blue_button_color
    {
        background-color: var(--blue)!important;
        color:var(--Color)!important;
    }
    .violet_button_color
    {
        background-color: var(--violet)!important;
        color:var(--Color)!important;
    }
    .purple_button_color
    {
        background-color: var(--purple)!important;
        color:var(--Color)!important;
    }
    .pink_button_color
    {
        background-color: var(--pink)!important;
        color:var(--Color)!important;
    }
    .brown_button_color
    {
        background-color: var(--brown)!important;
        color:var(--Color)!important;
    }
    .grey_button_color
    {
        background-color: var(--grey)!important;
        color:var(--Color)!important;
    }
    .black_button_color
    {
        background-color: var(--black)!important;
        color:var(--Color)!important;
    }
    .aiot_orange_button_color
    {
        background-color: var(--aiot_orange)!important;
        color:var(--Color)!important;
    }
    /*背景色白のボタンは枠線をsxで別途つけるように（背景との境がわからなくなります）*/
    .Thema_White_Color
    {
      color: var(--ThemaColor)!important;
      background-color: var(--Color)!important;
    }
    .Delete_White_Color
    {
      color: var(--DeleteColor)!important;
      background-color: var(--Color)!important;
    }
    .orange_button_white_color
    {
      color: var(--orange)!important;
      background-color: var(--Color)!important;
    }
    .yellow_button_white_color
    {
      color: var(--yellow)!important;
      background-color:var(--Color)!important;
    }
    .olive_button_white_color
    {
      color: var(--olive)!important;
      background-color:var(--Color)!important;
    }
    .green_button_white_color
    {
      color: var(--green)!important;
      background-color:var(--Color)!important;
    }
    .teal_button_white_color
    {
      color: var(--teal)!important;
      background-color:var(--Color)!important;
    }
    .blue_button_white_color
    {
      color: var(--blue)!important;
      background-color:var(--Color)!important;
    }
    .violet_button_white_color
    {
      color: var(--violet)!important;
      background-color:var(--Color)!important;
    }
    .purple_button_white_color
    {
      color: var(--purple)!important;
      background-color:var(--Color)!important;
    }
    .pink_button_white_color
    {
      color: var(--pink)!important;
      background-color:var(--Color)!important;
    }
    .brown_button_white_color
    {
      color: var(--brown)!important;
      background-color:var(--Color)!important;
    }
    .grey_button_white_color
    {
      color: var(--grey)!important;
      background-color:var(--Color)!important;
    }
    .black_button_white_color
    {
      color: var(--black)!important;
      background-color:var(--Color)!important;
    }
    .aiot_orange_button_white_color
    {
      color: var(--aiot_orange)!important;
      background-color:var(--Color)!important;
    }
    /*ボタン幅のクラス*/
    .button_SHORT_width/*仕様書にある『III 現在の「編集」の幅』のサイズ。指定されたボタンの幅の中で、短いサイズ*/
    {
      width: 64px!important;
      height: 39px!important;
      margin:5px!important;
    }
    .button_MEDIUM_width/*仕様書にある『I 現在の「キャンセル」の幅』のサイズ。指定されたボタンの幅の中で、真ん中のサイズ*/
    {
      width: 106.5px!important;
      height: 39px!important;
      margin:5px!important;
    }
    .button_LONG_width/*仕様書にある『II Iを横に2つ置いた幅』のサイズ。指定されたボタンの幅の中で、長いサイズ*/
    {
      width: calc(106.5*2+5*2)px!important;/*Iのボタン×2＋Iのマージン×2の幅を計算*/
      height: 39px!important;
      margin:5px!important;
    }
    .text_justify/*ボタンの両端揃えを設定するクラス*/
    {
      display: inline-block!important;
      width: 50px!important;
      text-align-last: justify!important;/*text-align: justifyのみでは最後の行（＝一行の単語）は両端揃えにならないため、単語を両端揃えにする*/
    }
    /*bodyタグ*/
    body 
    {
      position:relative;
      font-family: Roboto,Helvetica Neue,sans-serif;
      font-size: 16px !important;
      min-width:100%;
      min-height:100%; 
      height: 100%;
    }
    body, html
    {
      height: 100vh;
      margin:0px;
    }
    /*divタグ*/
    div 
    {
      font-family: Roboto,Helvetica Neue,sans-serif !important;
      font-size: 16px !important;
    }
    /*spanタグ*/
    span
    {
      font-size: 16px !important;
    }
    /*文字の太さを太くする（リンクに使用）*/
    .bolder
    {
      font-weight: bold!important;
    }
    /*紺色の文字（リンクなどで使用）*/
    .font-thema-color
    {
      background-color: transparent;
      color: var(--ThemaColor)!important;
      border: 0;
    }
    /*4つのアイコンにおいて＋以外のボタンでカーソルを変えたりしないためのクラス。今後動作させるようになったら不要になる*/
    .enable-link
    {
      cursor: pointer;
      pointer-events: auto;
    }
    /*4つのアイコンにおいて＋以外のボタンでカーソルを変えたりしないためのクラス。今後動作させるようになったら不要になる*/
    .enable-link:hover
    {
      color: var(--HoverColor)!important;
    }
    /*ログイン画面＞レイアウト設定クラス*/
    .login_grid
    {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      overflow:hidden;
    }
    /*ログイン画面＞ロゴとボタンのレイアウト設定クラス*/
    .login_column
    {
      max-width: 25%;
      padding: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-content: center;
      align-items: center;
    }
    /*ログイン画面＞ロゴのレイアウト設定クラス*/
    .login_logo
    {
      display:flex;
      justify-content: center;
      flex-direction: column;
      align-content: center;
      align-items: center;
      margin-bottom:10px;
    }
    /*ログイン画面＞ロゴの大きさ設定クラス*/
    .AIoT_logo
    {
      width: 120%;
      height: auto;
      margin-bottom:10px;
    }
    /*ログイン画面＞ログインボタンのレイアウト設定と外枠の大きさ設定クラス*/
    .login_button
    {
      display:flex;
      justify-content: center;
      flex-direction: column;
      align-content: center;
      align-items: center;
      margin-bottom:10px;
      margin-bottom:10px;
      width: 110%;
      height:auto;
    }
    /*ログイン画面＞ログインボタンの大きさ設定クラス*/
    .Button_Size_Setting
    {
      width: 110%;
      height:auto;
    }
    .list_margin
    {
      margin: 10px 10px 10px 20px;
    }
    .list_flow
    {
      overflow-x:hidden!important;
    }
    /*トップバー＞色設定クラス*/
    .Thema_Header_Color
    {
      background-color: var(--Color)!important;
      color: var(--ThemaColor)!important;
      border-radius: 4px;
      border-top: none;
      border-right: none;
      border-bottom: solid;
      border-left: solid;
      border-color: var(--ThemaColor)!important;
    }
    /*トップバー＞メールを右寄せ設定クラス*/
    .mail_right
    {
      display: flex;
      margin-left: auto;
      align-items: center;
    }
    /*サイドバー＞開くボタンの位置設定クラス*/
    .Chevron_open
    {
      display: flex!important ;
      justify-content: flex-end!important;
    }
    /*サイドバー＞開くボタンがマウスホーバーされたときの設定クラス*/
    .Chevron_open:hover
    {
      color: var(--HoverColor)!important;
    }
    /*サイドバー＞閉じるボタンの位置設定クラス*/
    .Chevron_close
    {
      display: flex!important;
      justify-content: flex-start!important;
    }
    /*サイドバー＞開くボタンがマウスホーバーされたときの設定クラス*/
    .Chevron_close:hover
    {
      color: var(--HoverColor)!important;
    }
    /*サイドバー＞とアイコンの間のpadding指定クラス*/
    .sidebar_text_padding
    {
      padding: 0 0 0 20px;
    }
    /*サイドバー＞のボタンとメニューの文字色指定クラス*/
    .sidebar_button_menu
    {
      color:var(--black)!important;
    }
    /*サイドバー＞のボタンとメニューのホーバー指定クラス*/
    .sidebar_button_menu:hover
    {
      color: var(--HoverColor)!important;
    }
    /*調理関連コンテンツ＞全体の画面において、背景色とpadding調整を設定するクラス*/
    .info_bg
    {
      padding: 10px;
    }
    /*調理関連コンテンツ＞全体の画面で見出しの上下のセンタリングを設定するクラス*/
    .info_horizon
    {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    /*調理関連コンテンツ＞全体の画面の保存ボタンで、右寄せにする設定クラス*/
    .info_button
    {
      margin: 0 0 0 auto;
    }
    /*調理関連コンテンツ＞検索絞り込みメニューのタイトルアイコンのサイズ*/
    .info_search_icon
    {
      max-width: 40px;
      max-height: 40px;
      margin-right: 40%;
    }
    /*調理関連コンテンツ＞検索絞り込みメニューのタイトルアイコンの下の表のレイアウト設定クラス*/
    .info_table_name_tag
    {
      display: flex;
      align-items: baseline;
      gap: 0px 0px;
      margin: 0 0 0 15%;
    }
    /*調理関連コンテンツ＞特集画面の画像サイズ調整*/
    .info_special_image
    {
      max-width: 90%;
    }
    /*調理関連コンテンツ＞レシピ関連情報の表2つの上下位置を上に合わせるための設定クラス*/
    .info_models
    {
      display: flex;
      align-items: flex-start;
    }
    /*調理関連コンテンツ＞レシピ関連情報の行追加のボタンを表の最後にするための設定クラス*/
    .info_model_tag
    {
      display: flex;
      align-items: flex-end;
    }
    /*調理関連コンテンツ＞レシピ関連情報の表2つの幅を同じにするための設定クラス*/
    .info_table_models
    {
      max-width: 40%;
    }
